/* eslint-disable @typescript-eslint/ban-types */
import { GraphQLResponse } from 'relay-runtime'

import { Middleware, MiddlewareNextFn, RelayRequest } from '@src/types/relay'

function noopFn() {}

export type RetryAfterFn = (attempt: number) => number | false
export type TimeoutAfterFn = (attempt: number) => number
export type ForceRetryFn = (runNow: Function, delay: number) => any
export type AbortFn = (msg?: string) => any
export type BeforeRetryCb = (meta: { forceRetry: Function }) => any

export class RetryMiddlewareError extends Error {
  constructor(msg: string) {
    super(msg)
    this.name = 'RetryMiddlewareError'
  }
}
interface Props {
  fetchTimeout?: number
  retryDelays?: number[]
  beforeRetry?: BeforeRetryCb
}

// https://github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/retry.js

export const retryMiddleware = (opt: Props): Middleware => {
  const timeoutAfterMs: TimeoutAfterFn = () => opt.fetchTimeout || 15000
  const retryAfterMs = (attempt: number) => {
    const delays = opt.retryDelays || [1000, 3000]
    return attempt < delays.length ? delays[attempt] : false
  }
  const retryOnStatusCode = () => false
  const beforeRetry = opt.beforeRetry || false

  return (next) => async (req) => {
    if (req.operation.operationKind === 'mutation') {
      return next(req)
    }

    return makeRetriableRequest({
      req,
      next,
      timeoutAfterMs,
      retryAfterMs,
      retryOnStatusCode,
      beforeRetry,
    })
  }
}

async function makeRetriableRequest(
  o: {
    req: RelayRequest
    next: MiddlewareNextFn
    timeoutAfterMs: (after: number) => number
    retryAfterMs: (attempt: number) => number | false
    retryOnStatusCode: () => void
    beforeRetry: BeforeRetryCb | false
  },
  delay = 0,
  attempt = 0
) {
  const makeRetry = async (prevError: Error): Promise<GraphQLResponse> => {
    const retryDelay = o.retryAfterMs(attempt)
    if (retryDelay) {
      return makeRetriableRequest(o, retryDelay, attempt + 1)
    }

    throw prevError
  }

  const makeRequest = async () => {
    try {
      const timeout = o.timeoutAfterMs(attempt)
      return await promiseWithTimeout(o.next(o.req), timeout, () => {
        // 서버에서 시간 내 응답이 없는 경우 커스텀 에러 생성 후 재시도
        const error = new RetryMiddlewareError(`Reached request timeout in ${timeout} ms`)
        return makeRetry(error)
      })
    } catch (e) {
      // 인터넷 연결이 끊겨서 서버의 응답이 오지 않는 경우 해당 에러 유지 및 재시도
      if (e && !(e as any).res && !(e instanceof RetryMiddlewareError) && (e as any).name !== 'AbortError') {
        return makeRetry(e as Error)
      }

      throw e
    }
  }

  if (attempt === 0) {
    return makeRequest()
  } else {
    const { promise, forceExec } = delayedExecution(makeRequest, delay)

    if (o.beforeRetry) {
      o.beforeRetry({ forceRetry: forceExec })
    }

    return promise
  }
}

function delayedExecution<T>(execFn: () => Promise<T>, delay = 0): { forceExec: () => any; promise: Promise<T> } {
  let forceExec = noopFn

  if (delay <= 0) {
    return {
      forceExec,
      promise: execFn(),
    }
  }

  const promise: Promise<T> = new Promise((resolve) => {
    let delayId: NodeJS.Timeout | null = null

    forceExec = () => {
      if (delayId) {
        clearTimeout(delayId)
        delayId = null
        resolve(execFn())
      }
    }

    delayId = setTimeout(() => {
      resolve(execFn())
    }, delay)
  })

  return { forceExec, promise }
}

function promiseWithTimeout<T>(promise: Promise<T>, timeoutMS: number, onTimeout: () => Promise<T>): Promise<T> {
  if (!timeoutMS) {
    return promise
  }

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      onTimeout().then(resolve).catch(reject)
    }, timeoutMS)

    promise
      .then((res) => {
        clearTimeout(timeoutId)
        resolve(res)
      })
      .catch((err) => {
        clearTimeout(timeoutId)
        reject(err)
      })
  })
}
