/* eslint-disable @typescript-eslint/ban-types */

import { Middleware } from '@src/types/relay'

interface Props {
  logger?: Function
}

// https://github.com/relay-tools/react-relay-network-modern/blob/master/src/middlewares/perf.js

export const perfMiddleware = (opts?: Props): Middleware => {
  // eslint-disable-next-line no-console
  const logger = (opts && opts.logger) || console.log.bind(console, '[RELAY-NETWORK]')

  return (next) => (req) => {
    const start = Date.now()

    return next(req).then((res) => {
      const end = new Date().getTime()
      logger(`[${end - start}ms] ${req.operation.id}`, req, res)
      return res
    })
  }
}
