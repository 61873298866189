/**
 * @generated SignedSource<<b92b92a74908ceffa62e47636e6763a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserReceivedPositiveApplicantCommentReviewCountResolver$data = {
  readonly receivedHasPositiveCommentJobReviewsCountByApplicant: number;
  readonly " $fragmentType": "UserReceivedPositiveApplicantCommentReviewCountResolver";
};
export type UserReceivedPositiveApplicantCommentReviewCountResolver$key = {
  readonly " $data"?: UserReceivedPositiveApplicantCommentReviewCountResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserReceivedPositiveApplicantCommentReviewCountResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserReceivedPositiveApplicantCommentReviewCountResolver",
  "selections": [
    {
      "alias": "receivedHasPositiveCommentJobReviewsCountByApplicant",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "evaluator": "APPLICANT",
            "hasComment": true,
            "rating": [
              "GOOD",
              "GREAT"
            ]
          }
        }
      ],
      "kind": "ScalarField",
      "name": "receivedJobReviewsCount",
      "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true,\"rating\":[\"GOOD\",\"GREAT\"]})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "25af5c6d82f71f9efcb38744f67a13dc";

export default node;
