import { graphql } from 'relay-runtime'

import { MainPageTopQuery$variables } from '@src/__generated__/MainPageTopQuery.graphql'
import { OPEN_INVITE_SHARE } from '@src/constants/action'

export const MainPageTopQueryNode = graphql`
  query MainPageTopQuery($isInviteEvent: Boolean!) {
    ...MainQuerystringHandler_query @arguments(isInviteEvent: $isInviteEvent)
  }
`

export const MainPageTopQueryVariables = ({ path }: { path: string }): MainPageTopQuery$variables => {
  const actions = new URL(path, 'file://').searchParams.get('actions')?.split(',')

  return {
    isInviteEvent: !!actions?.includes(OPEN_INVITE_SHARE),
  }
}
