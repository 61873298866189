import { graphql } from 'react-relay'
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments'

import { UserReceivedPositiveApplicantCommentReviewCountResolver$key } from '@src/__generated__/UserReceivedPositiveApplicantCommentReviewCountResolver.graphql'

/**
 *
 * @RelayResolver User.receivedPositiveApplicantCommentReviewCount: Int
 * @rootFragment UserReceivedPositiveApplicantCommentReviewCountResolver
 *
 * A greeting for the user which includes their name and title.
 */
export function receivedPositiveApplicantCommentReviewCount(
  userRef: UserReceivedPositiveApplicantCommentReviewCountResolver$key
): number {
  const user = readFragment(
    graphql`
      fragment UserReceivedPositiveApplicantCommentReviewCountResolver on User {
        receivedHasPositiveCommentJobReviewsCountByApplicant: receivedJobReviewsCount(
          filter: { hasComment: true, evaluator: APPLICANT, rating: [GOOD, GREAT] }
        )
      }
    `,
    userRef
  )

  return user.receivedHasPositiveCommentJobReviewsCountByApplicant ?? 0
}
