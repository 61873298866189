import { compare } from 'compare-versions'

class AppInfoStorage {
  private os = ''

  private appVersion = ''

  private osVersion = ''

  setAppInfo = ({ userAgent }: { userAgent?: string }) => {
    if (!userAgent) return
    const [, appVersion, rawOS, osVersion] = userAgent.split('/')
    const [, os] = rawOS.split(' ')

    this.os = os
    this.appVersion = appVersion
    this.osVersion = osVersion
  }

  getAppInfo = () => ({
    os: this.os,
    appVersion: this.appVersion,
    osVersion: this.osVersion,
  })

  compareVersions = (appVersion: string, inequalitySign: INEQUALITY_SIGN_TYPE): boolean =>
    compare(this.appVersion, appVersion, inequalitySign)

  compareOS = (appOS: 'Android' | 'iOS'): boolean => this.os === appOS

  satisfyAppVersion = (appOS: OS_TYPE, appVersion: string, inequalitySign: INEQUALITY_SIGN_TYPE): boolean =>
    this.compareOS(appOS) && this.compareVersions(appVersion, inequalitySign)

  satisfyBridgeVersion = () => {
    return this.satisfyAppVersion('Android', '6.4.9', '>=') || this.satisfyAppVersion('iOS', '6.8.3', '>=')
  }

  satisfyDaangnCallVersion = () => {
    return (
      (this.satisfyAppVersion('Android', '24.10.0', '>=')
        ? this.satisfyAppVersion('Android', '24.12.1', '>=')
        : this.satisfyAppVersion('Android', '23.52.2', '>=')) || this.satisfyAppVersion('iOS', '23.47.0', '>=')
    )
  }
}

export const appInfoStorage = new AppInfoStorage()

type OS_TYPE = 'Android' | 'iOS'
type INEQUALITY_SIGN_TYPE = '>' | '=' | '<' | '<=' | '>='
