import { compact, last } from 'lodash'

import storage from '@src/api/storage'
import { IS_DEV } from '@src/config'
import { EXPERIMENT_SEGMENTS } from '@src/constants/api'
import { EXPERIMENTS, SampleGroupKey, SAMPLE_GROUPS, TreatmentSymbol, ExperimentName } from '@src/constants/experiment'
import { UserRole } from '@src/types/user'

import { hasAcquisition } from './acquisition'
import { calcUserRole } from './user'

export const EXCLUDE_SUFFIX = 'exclude'
export const CONTROL_SUFFIX = 'control'
export const TREATMENT_MIDDLE = 'treatment'

const SAMPLE_GROUP_SEED = 'SAMPLE_GROUP_SEED'

export const isExcludeSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isExclude ?? true : true
}

export const isControlSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isControl ?? false : false
}

export const isTreatmentSegment = (segment?: string | null) => {
  return segment ? parseSegment(segment)?.isTreatment ?? false : false
}

export const getSegmentsFromLocal = () => {
  return storage.getItemFromLocal<string[]>(EXPERIMENT_SEGMENTS) || []
}

export const sampleGroupKeyToSegment = (key: SampleGroupKey) => {
  return `sample_${key.toLowerCase()}`
}

export const getSampleGroupSeed = (key: SampleGroupKey) => {
  return SAMPLE_GROUPS[key] * 1000
}

export const getSampleGroupSegmentsFromLocal = ({ role }: { role: UserRole }) => {
  const seed = storage.getItemFromLocal<number>(SAMPLE_GROUP_SEED) ?? 0
  const groups = (Object.keys(SAMPLE_GROUPS) as Array<SampleGroupKey>).reduce((acc, k) => {
    const sampleStandardSeed: number = getSampleGroupSeed(k as SampleGroupKey)
    const conditionalCondition = (() => {
      switch (k) {
        case 'PAID_USER':
          return hasAcquisition()
        case 'PAID_JOB_POST_AUTHOR':
          return hasAcquisition() && calcUserRole({ role }).isAuthor
        case 'JOB_POST_AUTHOR':
          return calcUserRole({ role }).isAuthor
        case 'ALL':
        default:
          return true
      }
    })()

    return sampleStandardSeed > seed && conditionalCondition ? [...acc, sampleGroupKeyToSegment(k)] : acc
  }, [] as Array<string>)

  return groups
}

export const setSegmentsAtLocal = (segments: string[]) => {
  storage.setItemAtLocal(EXPERIMENT_SEGMENTS, segments)
  return segments
}

export const makeExcludeSegment = (name: string, version: number) => `${name}-${version}-${EXCLUDE_SUFFIX}`

export const makeControlSegment = (name: string, version: number) => `${name}-${version}-${CONTROL_SUFFIX}`

export const makeTreatmentSegment = (name: string, version: number, group: TreatmentSymbol) => {
  return `${name}-${version}-${TREATMENT_MIDDLE}_${group.toLowerCase()}`
}

export const latestExperimentVersion = (name: string) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)
  if (!experiment) return 0

  return experiment.version
}

export const treatmentIndexToSymbol = (index: number): TreatmentSymbol => {
  return String.fromCharCode(65 + index) as TreatmentSymbol
}

export const treatmentSymbolToServerGroup = (symbol: TreatmentSymbol) => {
  return `TREATMENT_${symbol}`
}

export const excludeSegmentByExperimentName = (name: ExperimentName) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)
  if (!experiment) return null

  const excludeSegment = makeExcludeSegment(experiment.name, experiment.version)

  const segments = getSegmentsFromLocal()
  setSegmentsAtLocal([
    ...segments.filter((segment) => !(parseSegment(segment).name === experiment.name)),
    excludeSegment,
  ])
  return excludeSegment
}

export const makeAllSegmentsByExperimentName = (name: ExperimentName) => {
  const experiment = EXPERIMENTS.find((exp) => exp.name === name)

  if (!experiment) return null

  return [
    makeExcludeSegment(experiment.name, experiment.version),
    makeControlSegment(experiment.name, experiment.version),
    ...new Array(experiment.treatments.length)
      .fill(0)
      .map((_, idx) => makeTreatmentSegment(experiment.name, experiment.version, treatmentIndexToSymbol(idx))),
  ]
}

export type InitExperimentState = { region: { name1?: string; name2?: string; name3?: string } | null }

// segment(e.g): 'test_exp-1-treatment_a or test_exp-1-exclude'
export const parseSegment = (segment: string) => {
  const tokens = segment.split('-')

  return {
    name: tokens[0],
    version: parseInt(tokens[1], 10),
    isExclude: tokens[2] === 'exclude',
    isControl: tokens[2] === 'control',
    isTreatment: tokens[2].startsWith('treatment'),
    treatmentType: tokens[2].split('_')?.[1]?.toUpperCase() as TreatmentSymbol,
  }
}

const areRegionEqual = (region1: InitExperimentState['region'], region2: InitExperimentState['region']) => {
  return region1?.name1 === region2?.name1 && region1?.name2 === region2?.name2 && region1?.name3 === region2?.name3
}

export const initExperimentSegments = (state: InitExperimentState) => {
  const { region: currentRegion } = state
  const oldSegments = getSegmentsFromLocal()
  const isAdmin = storage.getIsAdmin()

  const enableSegments = compact(
    EXPERIMENTS.reduce((acc, { name, version, exclude, control, treatments, regions, isDynamicAssiginment }) => {
      const experimentName = name.toLowerCase()
      const segment = oldSegments.find((segment: string) => parseSegment(segment)?.name === experimentName)
      const isRegionExperiment = !!regions && regions.length > 0

      if (isDynamicAssiginment) {
        return acc
      }

      if (segment && !isRegionExperiment && version <= parseSegment(segment).version) {
        return [...acc, segment]
      }

      const map = [exclude, control, ...treatments.map((t) => t.percent)].reduce(
        (acc, val, idx) => [...acc, (idx === 0 ? 0 : acc[idx - 1]) + val],
        [] as number[]
      )
      const rate = Math.floor(Math.random() * (last(map) ?? 100))
      const inExperimentRegions = !regions || regions.some((r) => areRegionEqual(r, currentRegion))

      if (isRegionExperiment && !inExperimentRegions) {
        return acc
      }

      const index = !inExperimentRegions ? 0 : map.findIndex((item) => item > rate)

      if (isAdmin && index < 2) {
        return [
          ...acc,
          treatments.length ? makeTreatmentSegment(name, version, 'A') : makeControlSegment(name, version),
        ]
      }

      switch (true) {
        case index == 0:
          return [...acc, makeExcludeSegment(name, version)]
        case index == 1:
          return [...acc, makeControlSegment(name, version)]
        case index >= 2:
        default:
          return [...acc, makeTreatmentSegment(name, version, treatmentIndexToSymbol(index - 2))]
      }
    }, [] as string[])
  )

  const newSegments = compact(
    enableSegments.filter((enableSegment) => {
      const { name: enableSegmentName, version: enableSegmentVersion, isExclude } = parseSegment(enableSegment)
      if (isExclude) return false

      return !oldSegments.some((oldSegment) => {
        const { name, version } = parseSegment(oldSegment)
        return name === enableSegmentName && version === enableSegmentVersion
      })
    })
  )

  const disableSegments = compact(
    oldSegments.filter((oldSegment) => {
      const { name: oldSegmentName } = parseSegment(oldSegment)
      return !enableSegments.some((enableSegment) => parseSegment(enableSegment).name === oldSegmentName)
    })
  )

  setSegmentsAtLocal(enableSegments)

  return [enableSegments, newSegments, IS_DEV ? [] : disableSegments] as const
}

export const initEventSampleGroupSeed = () => {
  const seed = (() => {
    let seed = storage.getItemFromLocal<number>(SAMPLE_GROUP_SEED)
    if (!seed) {
      seed = Math.floor(Math.random() * 100000)
      storage.setItemAtLocal(SAMPLE_GROUP_SEED, seed)
    }
    return seed
  })()

  return seed
}
