/**
 * @generated SignedSource<<9caf82d537df7d1d1aab1259ffdfa585>>
 * @relayHash 601da6e95c54ad9285ebcbef9e110a44
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 601da6e95c54ad9285ebcbef9e110a44

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { receivedApplicantReviewCount as userReceivedApplicantReviewCountResolverType } from "../graphql/resolvers/UserReceivedApplicantReviewCount";
import { receivedPositiveApplicantCommentReviewCount as userReceivedPositiveApplicantCommentReviewCountResolverType } from "../graphql/resolvers/UserReceivedPositiveApplicantCommentReviewCount";
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
export type JobPostStatus = "ACCEPTED" | "CLOSED" | "CREATED" | "DELETED" | "PENDING" | "REJECTED" | "RETURNED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CHILD_CARE" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "MOVING_ASSISTANCE" | "NURSING" | "PET_CARE" | "SENIOR_CARE" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
export type JobPostPageQuery$variables = {
  id: string;
  regionId: number;
};
export type JobPostPageQuery$data = {
  readonly jobPost: {
    readonly _id: string;
    readonly author: {
      readonly badge?: {
        readonly items: ReadonlyArray<{
          readonly __typename: "FastResponseBadge";
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        }>;
      } | null | undefined;
      readonly hoianUser?: {
        readonly _id: number;
        readonly lastActivatedAt: Dateable | null | undefined;
        readonly receivedApplicantReviewCount: number | null | undefined;
        readonly receivedPositiveApplicantCommentReviewCount: number | null | undefined;
      };
    };
    readonly bringUpCount: number;
    readonly companyName: string | null | undefined;
    readonly deleted: boolean;
    readonly eventGroup: {
      readonly type: EventGroupType;
    } | null | undefined;
    readonly hidden: boolean;
    readonly id: string;
    readonly images: ReadonlyArray<{
      readonly url: string;
    }> | null | undefined;
    readonly jobTasks: ReadonlyArray<JobTaskType>;
    readonly status: JobPostStatus;
    readonly title: string | null | undefined;
    readonly workPeriod: WorkPeriod | null | undefined;
    readonly workplaceLocation: {
      readonly lat: number;
      readonly lng: number;
    } | null | undefined;
    readonly workplaceRegion: {
      readonly name3Region: {
        readonly _id: number;
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"JobPostFooter_jobPost" | "JobPostJobPost_jobPost" | "JobPostOtherPosts_jobPost" | "useJobPostReportMutation_jobPost" | "useJobPostShareMutation_jobPost">;
  } | null | undefined;
  readonly me: {
    readonly _id: number;
    readonly author: {
      readonly perfectAuthor: {
        readonly __typename: "Award";
      } | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"JobPostFooter_user" | "JobPostJobPost_user">;
  } | null | undefined;
  readonly statistic: {
    readonly liveJobPost: number;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"JobPostPersonalPosts_query">;
};
export type JobPostPageQuery = {
  response: JobPostPageQuery$data;
  variables: JobPostPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastActivatedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hidden",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bringUpCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workPeriod",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTasks",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Award",
  "kind": "LinkedField",
  "name": "perfectAuthor",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Statistic",
  "kind": "LinkedField",
  "name": "statistic",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "region",
          "variableName": "regionId"
        }
      ],
      "kind": "ScalarField",
      "name": "liveJobPost",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  "GREAT",
  "GOOD",
  "BAD"
],
v22 = {
  "name": "receivedApplicantReviewCount",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": "receivedJobReviewsCountByApplicant",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "evaluator": "APPLICANT",
              "rating": (v21/*: any*/)
            }
          }
        ],
        "kind": "ScalarField",
        "name": "receivedJobReviewsCount",
        "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
      }
    ],
    "type": "User",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v23 = {
  "name": "receivedPositiveApplicantCommentReviewCount",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": "receivedHasPositiveCommentJobReviewsCountByApplicant",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "evaluator": "APPLICANT",
              "hasComment": true,
              "rating": [
                "GOOD",
                "GREAT"
              ]
            }
          }
        ],
        "kind": "ScalarField",
        "name": "receivedJobReviewsCount",
        "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true,\"rating\":[\"GOOD\",\"GREAT\"]})"
      }
    ],
    "type": "User",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nickname",
  "storageKey": null
},
v25 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "thumbnail",
    "storageKey": null
  },
  (v2/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v27 = [
  (v26/*: any*/),
  (v2/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "region",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayRegionCheckinsCount",
  "storageKey": null
},
v30 = {
  "kind": "Literal",
  "name": "first",
  "value": 3
},
v31 = [
  (v2/*: any*/)
],
v32 = {
  "kind": "InlineFragment",
  "selections": (v31/*: any*/),
  "type": "Node",
  "abstractKey": "__isNode"
},
v33 = [
  (v15/*: any*/),
  (v2/*: any*/)
],
v34 = [
  (v3/*: any*/),
  (v2/*: any*/)
],
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v36 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "status": [
      "ACCEPTED",
      "CLOSED"
    ]
  }
},
v37 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closed",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v41 = {
  "name": "stringifiedSalary",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salaryType",
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v44 = [
  {
    "kind": "Literal",
    "name": "masking",
    "value": true
  }
],
v45 = [
  {
    "kind": "Literal",
    "name": "masking",
    "value": false
  }
],
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastBringUpDate",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workDates",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workDays",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobPostPageQuery",
    "selections": [
      {
        "alias": "jobPost",
        "args": (v1/*: any*/),
        "concreteType": "JobPost",
        "kind": "LinkedField",
        "name": "jobPostById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "hoianUser",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "fragment": {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "UserReceivedApplicantReviewCountResolver"
                        },
                        "kind": "RelayResolver",
                        "name": "receivedApplicantReviewCount",
                        "resolverModule": require('./../graphql/resolvers/UserReceivedApplicantReviewCount').receivedApplicantReviewCount,
                        "path": "jobPost.author.hoianUser.receivedApplicantReviewCount"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "fragment": {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "UserReceivedPositiveApplicantCommentReviewCountResolver"
                        },
                        "kind": "RelayResolver",
                        "name": "receivedPositiveApplicantCommentReviewCount",
                        "resolverModule": require('./../graphql/resolvers/UserReceivedPositiveApplicantCommentReviewCount').receivedPositiveApplicantCommentReviewCount,
                        "path": "jobPost.author.hoianUser.receivedPositiveApplicantCommentReviewCount"
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorBadge",
                    "kind": "LinkedField",
                    "name": "badge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": (v7/*: any*/),
                            "type": "FastResponseBadge",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "HoianUserAuthor",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventGroup",
            "kind": "LinkedField",
            "name": "eventGroup",
            "plural": false,
            "selections": [
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "workplaceRegion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "name3Region",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useJobPostShareMutation_jobPost"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useJobPostReportMutation_jobPost"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostFooter_jobPost"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostJobPost_jobPost"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostOtherPosts_jobPost"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v19/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostFooter_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "JobPostJobPost_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "JobPostPersonalPosts_query"
      },
      (v20/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobPostPageQuery",
    "selections": [
      {
        "alias": "jobPost",
        "args": (v1/*: any*/),
        "concreteType": "JobPost",
        "kind": "LinkedField",
        "name": "jobPostById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "hoianUser",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": (v25/*: any*/),
                        "storageKey": null
                      },
                      (v28/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "temperature",
                        "storageKey": null
                      },
                      (v29/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isOfficial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Author",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthorApplicationCount",
                            "kind": "LinkedField",
                            "name": "applicationCount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hire",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthorReviewCount",
                            "kind": "LinkedField",
                            "name": "reviewCount",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuthorApplicationResponse",
                            "kind": "LinkedField",
                            "name": "applicationResponse",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleAssignedAt",
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/),
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          (v30/*: any*/)
                        ],
                        "concreteType": "JobReview",
                        "kind": "LinkedField",
                        "name": "previewReviews",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Application",
                            "kind": "LinkedField",
                            "name": "application",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JobPost",
                                "kind": "LinkedField",
                                "name": "jobPost",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "hoianUser",
                                            "plural": false,
                                            "selections": (v31/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "HoianUserAuthor",
                                        "abstractKey": null
                                      },
                                      (v32/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "reviewer",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "image",
                                "plural": false,
                                "selections": (v33/*: any*/),
                                "storageKey": null
                              },
                              (v24/*: any*/),
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "reviewee",
                            "plural": false,
                            "selections": (v34/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workingConfirmed",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rating",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "comment",
                            "storageKey": null
                          },
                          (v35/*: any*/)
                        ],
                        "storageKey": "previewReviews(first:3)"
                      },
                      {
                        "name": "receivedApplicantGreatReviewCount",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": "receivedJobReviewsGreatCountByApplicant",
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "filter",
                                  "value": {
                                    "evaluator": "APPLICANT",
                                    "rating": [
                                      "GREAT"
                                    ]
                                  }
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "receivedJobReviewsCount",
                              "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\"]})"
                            }
                          ],
                          "type": "User",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "receivedApplicantGoodReviewCount",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": "receivedJobReviewsGoodCountByApplicant",
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "filter",
                                  "value": {
                                    "evaluator": "APPLICANT",
                                    "rating": [
                                      "GOOD"
                                    ]
                                  }
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "receivedJobReviewsCount",
                              "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GOOD\"]})"
                            }
                          ],
                          "type": "User",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "receivedApplicantBadReviewCount",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": "receivedJobReviewsBadCountByApplicant",
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "filter",
                                  "value": {
                                    "evaluator": "APPLICANT",
                                    "rating": [
                                      "BAD"
                                    ]
                                  }
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "receivedJobReviewsCount",
                              "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"BAD\"]})"
                            }
                          ],
                          "type": "User",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "fields": [
                              {
                                "kind": "Literal",
                                "name": "evaluator",
                                "value": "APPLICANT"
                              },
                              {
                                "kind": "Literal",
                                "name": "rating",
                                "value": (v21/*: any*/)
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "filter"
                          }
                        ],
                        "concreteType": "ReceivedJobReviewsExtend",
                        "kind": "LinkedField",
                        "name": "receivedJobReviewsExtend",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CountBySelectedItemCode",
                            "kind": "LinkedField",
                            "name": "countBySelectedItemCode",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "count",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "receivedJobReviewsExtend(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthorBadge",
                    "kind": "LinkedField",
                    "name": "badge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": true,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "_jobPosts",
                    "args": [
                      (v36/*: any*/),
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      }
                    ],
                    "concreteType": "JobPostConnection",
                    "kind": "LinkedField",
                    "name": "jobPosts",
                    "plural": false,
                    "selections": [
                      (v37/*: any*/)
                    ],
                    "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"CLOSED\"]},first:1)"
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v36/*: any*/),
                      (v30/*: any*/)
                    ],
                    "concreteType": "JobPostConnection",
                    "kind": "LinkedField",
                    "name": "jobPosts",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "JobPost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v38/*: any*/),
                              (v4/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Region",
                                "kind": "LinkedField",
                                "name": "workplaceRegion",
                                "plural": false,
                                "selections": (v27/*: any*/),
                                "storageKey": null
                              },
                              (v39/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AuthorBadge",
                                        "kind": "LinkedField",
                                        "name": "badge",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": true,
                                            "selections": [
                                              (v6/*: any*/),
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  (v40/*: any*/)
                                                ],
                                                "type": "FastResponseBadge",
                                                "abstractKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "hoianUser",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": "receivedHasCommentJobReviewsCount",
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "filter",
                                                "value": {
                                                  "evaluator": "APPLICANT",
                                                  "hasComment": true
                                                }
                                              }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "receivedJobReviewsCount",
                                            "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"hasComment\":true})"
                                          },
                                          (v2/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "HoianUserAuthor",
                                    "abstractKey": null
                                  },
                                  (v32/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v41/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v37/*: any*/)
                    ],
                    "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"CLOSED\"]},first:3)"
                  }
                ],
                "type": "HoianUserAuthor",
                "abstractKey": null
              },
              (v32/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BizAccount",
                    "kind": "LinkedField",
                    "name": "bizAccount",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v28/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "BizAccountAuthor",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EventGroup",
            "kind": "LinkedField",
            "name": "eventGroup",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EventGroupView",
                "kind": "LinkedField",
                "name": "view",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EventGroupLogo",
                    "kind": "LinkedField",
                    "name": "logo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullImageUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "workplaceRegion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "name3Region",
                "plural": false,
                "selections": (v34/*: any*/),
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name2",
                "storageKey": null
              },
              (v26/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": (v33/*: any*/),
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Permalink",
            "kind": "LinkedField",
            "name": "permalink",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publicId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "myApplication",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canceled",
                "storageKey": null
              },
              (v10/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewedAt",
                "storageKey": null
              },
              (v35/*: any*/)
            ],
            "storageKey": null
          },
          (v38/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPhoneContactable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Watch",
            "kind": "LinkedField",
            "name": "myWatch",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "totalApplicationCountInfo",
            "args": null,
            "concreteType": "JobPostApplicationCountInfo",
            "kind": "LinkedField",
            "name": "applicationCountInfo",
            "plural": false,
            "selections": [
              (v42/*: any*/)
            ],
            "storageKey": null
          },
          (v43/*: any*/),
          {
            "alias": "_author",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "hoianUser",
                    "plural": false,
                    "selections": (v34/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "HoianUserAuthor",
                "abstractKey": null
              },
              (v32/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "maskingContent",
            "args": (v44/*: any*/),
            "kind": "ScalarField",
            "name": "content",
            "storageKey": "content(masking:true)"
          },
          {
            "alias": "unMaskingContent",
            "args": (v45/*: any*/),
            "kind": "ScalarField",
            "name": "content",
            "storageKey": "content(masking:false)"
          },
          {
            "alias": "maskingTitle",
            "args": (v44/*: any*/),
            "kind": "ScalarField",
            "name": "title",
            "storageKey": "title(masking:true)"
          },
          {
            "alias": "unMaskingTitle",
            "args": (v45/*: any*/),
            "kind": "ScalarField",
            "name": "title",
            "storageKey": "title(masking:false)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessLicense",
            "kind": "LinkedField",
            "name": "businessLicense",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Identification",
            "kind": "LinkedField",
            "name": "identification",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v46/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consideredQualifications",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "welfare",
            "storageKey": null
          },
          (v47/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workplaceRoadAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "watchCount",
            "storageKey": null
          },
          (v48/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "option",
                "value": {
                  "canceled": false
                }
              }
            ],
            "concreteType": "JobPostApplicationCountInfo",
            "kind": "LinkedField",
            "name": "applicationCountInfo",
            "plural": false,
            "selections": [
              (v42/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewed",
                "storageKey": null
              }
            ],
            "storageKey": "applicationCountInfo(option:{\"canceled\":false})"
          },
          (v41/*: any*/),
          {
            "name": "stringifiedBringUpPassedDate",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                (v46/*: any*/),
                (v43/*: any*/)
              ],
              "type": "JobPost",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          {
            "name": "stringifiedWorkDateAndDays",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                (v47/*: any*/),
                (v48/*: any*/)
              ],
              "type": "JobPost",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          {
            "name": "stringifiedWorkTime",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workTimeStart",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workTimeEnd",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isWorkTimeNegotiable",
                  "storageKey": null
                },
                (v14/*: any*/)
              ],
              "type": "JobPost",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v19/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NewJobPostSubscription",
            "kind": "LinkedField",
            "name": "newJobPostFilterSubscription",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isHolding",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthYear",
                "storageKey": null
              },
              (v40/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "standardJobPost",
        "args": (v1/*: any*/),
        "concreteType": "JobPost",
        "kind": "LinkedField",
        "name": "jobPostById",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "workplaceRegion",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v26/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/),
          (v2/*: any*/),
          (v14/*: any*/),
          (v4/*: any*/),
          (v41/*: any*/),
          (v39/*: any*/),
          (v38/*: any*/)
        ],
        "storageKey": null
      },
      (v20/*: any*/)
    ]
  },
  "params": {
    "id": "601da6e95c54ad9285ebcbef9e110a44",
    "metadata": {},
    "name": "JobPostPageQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3b6697cef89a7b30621c18725172fc34";

export default node;
