import { graphql } from 'relay-runtime'

import { MainPageContentsQuery$variables } from '@src/__generated__/MainPageContentsQuery.graphql'
import { RegionRange } from '@src/types/jobPost'

export const MainPageContentsQueryNode = graphql`
  query MainPageContentsQuery($topPremiumWhere: FeedWhereInput!, $jobPostsFilter: UserJobPostsFilterInput) {
    me {
      region {
        name3
      }
      ...NewActivityBadgeAcquiredCallout_user
    }
    ...MainTopPremiumAdJobPosts_query @arguments(where: $topPremiumWhere)
    ...MainWritings_query @arguments(filter: $jobPostsFilter)
    ...RestrictionInlineAlert_query @arguments(filter: { reasonTypes: [DID_NOT_KEEP_PROMISE] })
    ...MainPageContentsBannerSection_query
  }
`

export function MainPageContentsQueryVariables({ regionId }: { regionId: number }): MainPageContentsQuery$variables {
  return {
    jobPostsFilter: { status: ['ACCEPTED', 'PENDING'] },
    topPremiumWhere: {
      region: regionId,
      regionRange: RegionRange.RANGE_3,
    },
  }
}
