import { UserRole } from '@src/types/user'

export const calcUserRole = (user?: Nullable<{ role?: UserRole }>) => {
  return {
    isAuthor: user?.role === 'AUTHOR' || user?.role === 'AUTHOR_APPLICANT',
    isApplicant: user?.role === 'APPLICANT' || user?.role === 'AUTHOR_APPLICANT',
    isNone: user?.role === 'NONE',
  }
}

export const isKoreanName = (name: string) => {
  // 조건에 맞는 한글로 된 성씨와 이름을 확인하기 위한 정규식
  const regex =
    /^[김이박최정강조윤장임한오서신권황안송전홍유고문양손배조백허유남심노정하곽성차주우구신임전민유류나진지엄채원천방공괵국금기길도라마맹명반변복부사석선설소승어여연염옥위육은음인제추탁태팽편표함현형호감계][가-힣]{1,3}$/

  // 정규식을 사용하여 입력된 이름이 한국인 이름인지 확인합니다.
  return regex.test(name)
}
