/**
 * @generated SignedSource<<d84c9c902835af2aa6d3afe0b75edc75>>
 * @relayHash 022315cf838795385b1dea2f986ad45a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 022315cf838795385b1dea2f986ad45a

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertisementExposureType = "STANDARD" | "TOP_PREMIUM" | "%future added value";
export type AdvertisementStatus = "DAILY_BUDGET_EXCEEDED" | "DELETED" | "DENIED" | "ONGOING" | "OUT_DATED" | "PAUSED" | "READY" | "START_OUT_DATED" | "STOPPED" | "TOTAL_BUDGET_EXCEEDED" | "%future added value";
export type ConsideredQualification = "ADULT_ALL_AGES" | "BEGINNER" | "FOREIGNER" | "MINOR" | "%future added value";
export type Day = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED" | "%future added value";
export type EventGroupType = "BUDDHA_BIRTHDAY" | "CHILDREN_DAY" | "CHRISTMAS" | "Chuseok" | "DAANGN_EVENT" | "LunarNewYear" | "MEMORIAL_DAY" | "PEPERO_DAY" | "WHITE_DAY" | "%future added value";
export type JobPostAuthorType = "BIZ_ACCOUNT" | "HOIAN_USER" | "%future added value";
export type JobPostStatus = "ACCEPTED" | "CLOSED" | "CREATED" | "DELETED" | "PENDING" | "REJECTED" | "RETURNED" | "%future added value";
export type JobTaskType = "BAKING" | "BEVERAGE_MAKING" | "CHILD_CARE" | "CLEANING" | "CONVENIENCE_STORE" | "ETC" | "HOUSEWORK" | "KIDS_PICK_UP" | "KITCHEN_ASSISTANCE" | "KITCHEN_COOK" | "LEAFLET_DISTRIBUTION" | "LIGHT_WORK" | "MOVING_ASSISTANCE" | "NURSING" | "PET_CARE" | "SENIOR_CARE" | "SERVING" | "SHOP_MANAGEMENT" | "TUTORING" | "%future added value";
export type RegionDepthType = "DEPTH_1" | "DEPTH_2" | "DEPTH_3" | "DEPTH_4" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type WorkPeriod = "LESS_THAN_A_MONTH" | "MORE_THAN_A_MONTH" | "%future added value";
export type MainFeedWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type MainFeedFilterInput = {
  authorType?: JobPostAuthorType | null | undefined;
  consideredQualifications?: ReadonlyArray<ConsideredQualification> | null | undefined;
  eventGroup?: EventGroupType | null | undefined;
  expired?: boolean | null | undefined;
  jobTasks?: ReadonlyArray<JobTaskType> | null | undefined;
  publishedPeriod?: SearchPeriodInput | null | undefined;
  salary?: number | null | undefined;
  status?: ReadonlyArray<JobPostStatus> | null | undefined;
  workDates?: ReadonlyArray<Dateable> | null | undefined;
  workDays?: ReadonlyArray<Day> | null | undefined;
  workPeriod?: WorkPeriod | null | undefined;
  workTime?: SearchWorkTimeInput | null | undefined;
};
export type SearchPeriodInput = {
  end?: Dateable | null | undefined;
  start?: Dateable | null | undefined;
};
export type SearchWorkTimeInput = {
  end?: string | null | undefined;
  start?: string | null | undefined;
};
export type RegionWhereInput = {
  _id: number;
};
export type RegionNeighborsWhereInput = {
  depth?: RegionDepthType | null | undefined;
  range: RegionRange;
};
export type MainPageFeedQuery$variables = {
  filter?: MainFeedFilterInput | null | undefined;
  neighborsWhere: RegionNeighborsWhereInput;
  regionWhere: RegionWhereInput;
  where: MainFeedWhereInput;
};
export type MainPageFeedQuery$data = {
  readonly me: {
    readonly __typename: "User";
    readonly _id: number;
    readonly jobPosts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly advertisements: ReadonlyArray<{
            readonly exposureType: AdvertisementExposureType;
            readonly status: AdvertisementStatus;
          }> | null | undefined;
          readonly " $fragmentSpreads": FragmentRefs<"JobPostCard_jobPost">;
        };
      }>;
    };
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MainFilterArea_query" | "MainJobPosts_query" | "MainNewJobPostFilterSubscriptionCallout_query">;
};
export type MainPageFeedQuery = {
  response: MainPageFeedQuery$data;
  variables: MainPageFeedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "neighborsWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionWhere"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "status": [
        "ACCEPTED",
        "PENDING"
      ]
    }
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 2
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exposureType",
  "storageKey": null
},
v9 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "filter"
},
v10 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    (v11/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "workplaceRegion",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v15/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closed",
  "storageKey": null
},
v19 = [
  (v5/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuthorBadge",
          "kind": "LinkedField",
          "name": "badge",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "items",
              "plural": true,
              "selections": (v19/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "hoianUser",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "name": "receivedApplicantReviewCount",
              "args": null,
              "fragment": {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "receivedJobReviewsCountByApplicant",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "evaluator": "APPLICANT",
                          "rating": [
                            "GREAT",
                            "GOOD",
                            "BAD"
                          ]
                        }
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "receivedJobReviewsCount",
                    "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
                  }
                ],
                "type": "User",
                "abstractKey": null
              },
              "kind": "RelayResolver",
              "storageKey": null,
              "isOutputType": true
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Author",
              "kind": "LinkedField",
              "name": "author",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Award",
                  "kind": "LinkedField",
                  "name": "perfectAuthor",
                  "plural": false,
                  "selections": (v19/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v11/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "HoianUserAuthor",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v11/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "EventGroup",
  "kind": "LinkedField",
  "name": "eventGroup",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EventGroupView",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventGroupLogo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullImageUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementScore",
  "storageKey": null
},
v23 = {
  "name": "stringifiedSalary",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salaryType",
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workDays",
  "storageKey": null
},
v25 = {
  "name": "stringifiedWorkableDateAndDays",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workDates",
        "storageKey": null
      },
      (v24/*: any*/)
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v26 = {
  "name": "stringifiedWorkTime",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workTimeStart",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workTimeEnd",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isWorkTimeNegotiable",
        "storageKey": null
      },
      (v12/*: any*/)
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v27 = {
  "name": "stringifiedBringUpPassedDate",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastBringUpDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "publishedAt",
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v28 = [
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageFeedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "JobPostCard_jobPost"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"PENDING\"]},first:2)"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainJobPosts_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MainNewJobPostFilterSubscriptionCallout_query"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "neighborsWhere",
            "variableName": "neighborsWhere"
          },
          {
            "kind": "Variable",
            "name": "regionWhere",
            "variableName": "regionWhere"
          }
        ],
        "kind": "FragmentSpread",
        "name": "MainFilterArea_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MainPageFeedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"ACCEPTED\",\"PENDING\"]},first:2)"
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NewJobPostSubscription",
            "kind": "LinkedField",
            "name": "newJobPostFilterSubscription",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isHolding",
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workPeriods",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Region",
                "kind": "LinkedField",
                "name": "regions",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTasks",
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v28/*: any*/),
        "concreteType": "FeedConnection",
        "kind": "LinkedField",
        "name": "mainFeed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FeedCard",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FeedAdvertisementInfo",
                    "kind": "LinkedField",
                    "name": "advertisement",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdvertisementCallback",
                        "kind": "LinkedField",
                        "name": "callback",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hide",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "impression",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "click",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "jobPost",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bringUpCount",
                        "storageKey": null
                      },
                      (v23/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v28/*: any*/),
        "filters": [
          "where",
          "filter"
        ],
        "handle": "connection",
        "key": "MainJobPosts_mainFeed",
        "kind": "LinkedHandle",
        "name": "mainFeed"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "regionWhere"
          }
        ],
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "region",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "where",
                "variableName": "neighborsWhere"
              }
            ],
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "neighbors",
            "plural": true,
            "selections": [
              (v15/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "022315cf838795385b1dea2f986ad45a",
    "metadata": {},
    "name": "MainPageFeedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "83f6a3e1fdfc27a265a871c1ca422103";

export default node;
