import { last } from 'lodash'

import storage from '@src/api/storage'
import { ACQUISITION } from '@src/constants/api'
import { AnalyticsClickEventKey, AnalyticsShowEventKey } from '@src/types/__generated__/analytics'

export const getAcquisitionsFromLocal = () => {
  const acquisitions = storage.getItemFromLocal<string[]>(ACQUISITION) || []
  return acquisitions
}

export const setAcquisitionsToLocal = (acquisitions: string[]) => {
  storage.setItemAtLocal(ACQUISITION, acquisitions)
  return acquisitions
}

export const hasAcquisition = (acquisition?: string) => {
  const acquisitions = getAcquisitionsFromLocal()
  return acquisition ? acquisitions.includes(acquisition) : !!acquisitions?.length
}

export const addAcquisitionToLocal = (acquisition: string) => {
  const acquisitions = getAcquisitionsFromLocal()
  const targetSuffixLength = (last(acquisition.split('_'))?.length ?? 0) + 1
  const acquisitionPrefix = acquisition.slice(0, acquisition.length - targetSuffixLength)
  if (acquisitions.includes(acquisitionPrefix)) {
    return acquisitions
  }

  const allAcquisitions = [...acquisitions, acquisition]
  setAcquisitionsToLocal(allAcquisitions)
  return allAcquisitions
}

export const acquisitionEvents: { show: Array<AnalyticsShowEventKey>; click: Array<AnalyticsClickEventKey> } = {
  show: ['main_1_within_day', 'create_job_post'],
  click: ['create_resume', 'apply', 'create_job_post', 'create_review'],
}
