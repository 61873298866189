/**
 * @generated SignedSource<<03a09514136168390d342874a85ffca7>>
 * @relayHash e4a09543a352ed96d58186a645e53b95
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e4a09543a352ed96d58186a645e53b95

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobPostStatus = "ACCEPTED" | "CLOSED" | "CREATED" | "DELETED" | "PENDING" | "REJECTED" | "RETURNED" | "%future added value";
export type RegionRange = "ADJACENT" | "MY" | "NO_PUBLISH" | "RANGE_2" | "RANGE_3" | "RANGE_4" | "%future added value";
export type FeedWhereInput = {
  region: number;
  regionRange?: RegionRange;
};
export type UserJobPostsFilterInput = {
  status?: ReadonlyArray<JobPostStatus> | null | undefined;
};
export type MainPageContentsQuery$variables = {
  jobPostsFilter?: UserJobPostsFilterInput | null | undefined;
  topPremiumWhere: FeedWhereInput;
};
export type MainPageContentsQuery$data = {
  readonly me: {
    readonly region: {
      readonly name3: string | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"NewActivityBadgeAcquiredCallout_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"MainPageContentsBannerSection_query" | "MainTopPremiumAdJobPosts_query" | "MainWritings_query" | "RestrictionInlineAlert_query">;
};
export type MainPageContentsQuery = {
  response: MainPageContentsQuery$data;
  variables: MainPageContentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "jobPostsFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topPremiumWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name3",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "topPremiumWhere"
},
v4 = {
  "kind": "Variable",
  "name": "filter",
  "variableName": "jobPostsFilter"
},
v5 = {
  "kind": "Literal",
  "name": "filter",
  "value": {
    "reasonTypes": [
      "DID_NOT_KEEP_PROMISE"
    ]
  }
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "name": "stringifiedSalary",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salary",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salaryType",
        "storageKey": null
      }
    ],
    "type": "JobPost",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "workplaceRegion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name2",
      "storageKey": null
    },
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Point",
  "kind": "LinkedField",
  "name": "workplaceLocation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lng",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "name": "hasFastResponseBadge",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthorBadge",
        "kind": "LinkedField",
        "name": "badge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "type": "FastResponseBadge",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "HoianUserAuthor",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v17 = {
  "name": "receivedApplicantReviewCount",
  "args": null,
  "fragment": {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": "receivedJobReviewsCountByApplicant",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "evaluator": "APPLICANT",
              "rating": [
                "GREAT",
                "GOOD",
                "BAD"
              ]
            }
          }
        ],
        "kind": "ScalarField",
        "name": "receivedJobReviewsCount",
        "storageKey": "receivedJobReviewsCount(filter:{\"evaluator\":\"APPLICANT\",\"rating\":[\"GREAT\",\"GOOD\",\"BAD\"]})"
      }
    ],
    "type": "User",
    "abstractKey": null
  },
  "kind": "RelayResolver",
  "storageKey": null,
  "isOutputType": true
},
v18 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exposureType",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slotCount",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentSlotCount",
  "storageKey": null
},
v23 = [
  (v8/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bringUpCount",
  "storageKey": null
},
v26 = [
  (v4/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastBringUpDate",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCash",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v31 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextAvailableBringUpDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewActivityBadgeAcquiredCallout_user"
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainTopPremiumAdJobPosts_query"
      },
      {
        "args": [
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "MainWritings_query"
      },
      {
        "args": [
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "RestrictionInlineAlert_query"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MainPageContentsBannerSection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MainPageContentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nickname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Resume",
            "kind": "LinkedField",
            "name": "resume",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "name": "acquiredActivityBadgeCount",
            "args": null,
            "fragment": {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserActivityBadge",
                  "kind": "LinkedField",
                  "name": "activityBadge",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "items",
                      "plural": true,
                      "selections": [
                        (v8/*: any*/),
                        (v7/*: any*/),
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "User",
              "abstractKey": null
            },
            "kind": "RelayResolver",
            "storageKey": null,
            "isOutputType": true
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "status": [
                    "PENDING",
                    "ACCEPTED"
                  ]
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 2
              }
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "hoianUser",
                                "plural": false,
                                "selections": [
                                  (v17/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(filter:{\"status\":[\"PENDING\",\"ACCEPTED\"]},first:2)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPostExtend",
            "kind": "LinkedField",
            "name": "jobPostsExtend",
            "plural": false,
            "selections": [
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v5/*: any*/),
              {
                "kind": "Literal",
                "name": "limit",
                "value": 1
              }
            ],
            "concreteType": "UserActionRestriction",
            "kind": "LinkedField",
            "name": "restrictedActions",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": "restrictedActions(filter:{\"reasonTypes\":[\"DID_NOT_KEEP_PROMISE\"]},limit:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsOfService",
            "kind": "LinkedField",
            "name": "termsOfService",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "applicantTermsAgreed",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "page",
            "value": 1
          },
          {
            "kind": "Literal",
            "name": "size",
            "value": 4
          },
          (v3/*: any*/)
        ],
        "concreteType": "FeedCard",
        "kind": "LinkedField",
        "name": "topFeed",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FeedAdvertisementInfo",
            "kind": "LinkedField",
            "name": "advertisement",
            "plural": false,
            "selections": [
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AdvertisementCallback",
                "kind": "LinkedField",
                "name": "callback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hide",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "impression",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "click",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPost",
            "kind": "LinkedField",
            "name": "jobPost",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "hoianUser",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Author",
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Award",
                                "kind": "LinkedField",
                                "name": "perfectAuthor",
                                "plural": false,
                                "selections": (v23/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuthorBadge",
                        "kind": "LinkedField",
                        "name": "badge",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": (v23/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "type": "HoianUserAuthor",
                    "abstractKey": null
                  },
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v24/*: any*/),
              (v25/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "author",
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v26/*: any*/),
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deleted",
                        "storageKey": null
                      },
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v19/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "hoianUser",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BusinessLicense",
                                    "kind": "LinkedField",
                                    "name": "businessLicense",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "validatedAt",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Image",
                                        "kind": "LinkedField",
                                        "name": "images",
                                        "plural": true,
                                        "selections": [
                                          (v24/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Identification",
                                    "kind": "LinkedField",
                                    "name": "identification",
                                    "plural": false,
                                    "selections": (v23/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BizUser",
                                    "kind": "LinkedField",
                                    "name": "bizUser",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Advertiser",
                                        "kind": "LinkedField",
                                        "name": "latestAdvertiser",
                                        "plural": false,
                                        "selections": [
                                          (v24/*: any*/),
                                          (v29/*: any*/),
                                          (v6/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/),
                                  {
                                    "name": "isPotentialCloseJobPostAbuser",
                                    "args": null,
                                    "fragment": {
                                      "kind": "InlineFragment",
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "JobPostExtend",
                                          "kind": "LinkedField",
                                          "name": "jobPostsExtend",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "kind": "ScalarField",
                                              "name": "closedJobPostCount",
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "type": "User",
                                      "abstractKey": null
                                    },
                                    "kind": "RelayResolver",
                                    "storageKey": null,
                                    "isOutputType": true
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "JobPostExtend",
                                    "kind": "LinkedField",
                                    "name": "jobPostsExtend",
                                    "plural": false,
                                    "selections": [
                                      (v21/*: any*/),
                                      (v22/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "availableSlotCount",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "HoianUserAuthor",
                            "abstractKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "JobPostAdvertisement",
                        "kind": "LinkedField",
                        "name": "advertisements",
                        "plural": true,
                        "selections": [
                          (v19/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "materialId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Advertiser",
                            "kind": "LinkedField",
                            "name": "advertiser",
                            "plural": false,
                            "selections": [
                              (v29/*: any*/),
                              (v6/*: any*/),
                              (v24/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "budget",
                            "storageKey": null
                          },
                          (v20/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "privatizedReason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "privatizedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "advertisementAvailable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "exposureItems",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": "freeExposureStartAt",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exposureStartAt",
                                "storageKey": null
                              },
                              {
                                "alias": "freeExposureEndAt",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exposureEndAt",
                                "storageKey": null
                              }
                            ],
                            "type": "JobPostFreeExposureItem",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exposureStartAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exposureEndAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "exposureHours",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentDueAt",
                                "storageKey": null
                              }
                            ],
                            "type": "JobPostPaidExposureItem",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "workplaceRegion",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "option",
                            "value": {
                              "canceled": false
                            }
                          }
                        ],
                        "concreteType": "JobPostApplicationCountInfo",
                        "kind": "LinkedField",
                        "name": "applicationCountInfo",
                        "plural": false,
                        "selections": [
                          (v30/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ApplicationCountByStatus",
                            "kind": "LinkedField",
                            "name": "countByStatus",
                            "plural": true,
                            "selections": [
                              (v19/*: any*/),
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "applicationCountInfo(option:{\"canceled\":false})"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hidden",
                        "storageKey": null
                      },
                      {
                        "alias": "notViewedApplications",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "filter",
                            "value": {
                              "canceled": false,
                              "expired": false,
                              "statusList": [
                                "WAITING"
                              ],
                              "viewed": false
                            }
                          },
                          (v31/*: any*/)
                        ],
                        "concreteType": "ApplicationConnection",
                        "kind": "LinkedField",
                        "name": "applications",
                        "plural": false,
                        "selections": [
                          (v30/*: any*/)
                        ],
                        "storageKey": "applications(filter:{\"canceled\":false,\"expired\":false,\"statusList\":[\"WAITING\"],\"viewed\":false},first:1)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "engagementScore",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workPeriod",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "welfare",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "consideredQualifications",
                        "storageKey": null
                      },
                      (v25/*: any*/),
                      (v32/*: any*/),
                      (v8/*: any*/),
                      {
                        "name": "stringifiedBringUpPassedDate",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v27/*: any*/),
                            (v28/*: any*/)
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      },
                      {
                        "name": "nextAvailableBringUpTimes",
                        "args": null,
                        "fragment": {
                          "kind": "InlineFragment",
                          "selections": [
                            (v25/*: any*/),
                            (v32/*: any*/)
                          ],
                          "type": "JobPost",
                          "abstractKey": null
                        },
                        "kind": "RelayResolver",
                        "storageKey": null,
                        "isOutputType": true
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v26/*: any*/),
            "filters": [
              "filter"
            ],
            "handle": "connection",
            "key": "MainWritingsList_jobPosts",
            "kind": "LinkedHandle",
            "name": "jobPosts"
          },
          {
            "alias": "log_is_biz",
            "args": [
              (v31/*: any*/)
            ],
            "concreteType": "JobPostConnection",
            "kind": "LinkedField",
            "name": "jobPosts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JobPostEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JobPost",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "jobPosts(first:1)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JobPostExtend",
            "kind": "LinkedField",
            "name": "jobPostsExtend",
            "plural": false,
            "selections": [
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "e4a09543a352ed96d58186a645e53b95",
    "metadata": {},
    "name": "MainPageContentsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7433bfdeee7aa057f2bbba34bfba51c3";

export default node;
