import { graphql } from 'relay-runtime'

import { MainPageFeedQuery$variables } from '@src/__generated__/MainPageFeedQuery.graphql'
import { RESET_FILTERS } from '@src/constants/action'
import { DEFAULT_FILTER_SET } from '@src/constants/filter'
import { FilterState } from '@src/types/filter'
import { RegionRange, WorkDay, WorkPeriod } from '@src/types/jobPost'

export const MainPageFeedQueryNode = graphql`
  query MainPageFeedQuery(
    $where: MainFeedWhereInput!
    $filter: MainFeedFilterInput
    $regionWhere: RegionWhereInput!
    $neighborsWhere: RegionNeighborsWhereInput!
  ) {
    me {
      _id
      __typename
      jobPosts(filter: { status: [ACCEPTED, PENDING] }, first: 2) {
        edges {
          node {
            advertisements {
              status
              exposureType
            }
            ...JobPostCard_jobPost
          }
        }
      }
    }
    ...MainJobPosts_query @arguments(where: $where, filter: $filter)
    ...MainNewJobPostFilterSubscriptionCallout_query
    ...MainFilterArea_query @arguments(regionWhere: $regionWhere, neighborsWhere: $neighborsWhere)
  }
`

export const MainPageFeedQueryVariables = ({
  regionId,
  filterState,
}: {
  regionId: number
  filterState: FilterState
}): MainPageFeedQuery$variables => {
  return {
    where: {
      region: regionId,
      regionRange: filterState.regionRange,
    },
    regionWhere: {
      _id: regionId,
    },
    neighborsWhere: {
      range: filterState.regionRange,
      depth: 'DEPTH_3',
    },
    filter: {
      workPeriod: filterState.workPeriod,
      workDays: filterState.workDays,
      workTime: filterState.workTime,
      jobTasks: filterState.jobTasks,
    },
  }
}

export function MainPageFeedQueryPathToFilterState(path: string): FilterState {
  const u = new URL(path, 'file://')

  const regionRange = u.searchParams.get('regionRange') as RegionRange | null
  const workPeriod = u.searchParams.get('workPeriod') as WorkPeriod | null
  const workDays = (u.searchParams.get('workDays') ?? '').split(',').filter((v) => v !== '') as WorkDay[]
  const resetFilter = (u.searchParams.get('actions') ?? '').split(',').includes(RESET_FILTERS)

  if (resetFilter) {
    return DEFAULT_FILTER_SET
  }

  return {
    regionRange: regionRange ?? RegionRange.RANGE_3,
    workPeriod: workPeriod ?? undefined,
    jobTasks: [],
    workDays,
    workTime: undefined,
    filterStatus: {
      any: false,
      region: false,
      workPeriod: false,
      jobTasks: false,
      workDays: false,
      workTime: false,
    },
  }
}
