import { graphql } from 'relay-runtime'

export const JobPostPageQueryNode = graphql`
  query JobPostPageQuery($id: ID!, $regionId: Int!) {
    jobPost: jobPostById(id: $id) {
      id
      _id
      title
      author {
        ... on HoianUserAuthor {
          hoianUser {
            _id
            receivedApplicantReviewCount
            receivedPositiveApplicantCommentReviewCount
            lastActivatedAt
          }
          badge {
            items {
              ... on FastResponseBadge {
                __typename
              }
            }
          }
        }
      }
      status
      hidden
      deleted
      eventGroup {
        type
      }
      # eslint-disable-next-line relay/unused-fields
      workplaceLocation {
        lat
        lng
      }
      workplaceRegion {
        name3Region {
          _id
        }
      }
      bringUpCount
      companyName
      # eslint-disable-next-line relay/unused-fields
      images {
        url
      }
      workPeriod
      jobTasks
      ...useJobPostShareMutation_jobPost
      ...useJobPostReportMutation_jobPost
      ...JobPostFooter_jobPost
      ...JobPostJobPost_jobPost
      ...JobPostOtherPosts_jobPost
    }
    me {
      _id
      author {
        perfectAuthor {
          __typename
        }
      }
      ...JobPostFooter_user
      ...JobPostJobPost_user
    }
    ...JobPostPersonalPosts_query @arguments(id: $id)
    statistic {
      liveJobPost(region: $regionId)
    }
  }
`
