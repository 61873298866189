import { FilterState } from '@src/types/filter'
import { RegionRange } from '@src/types/jobPost'

export const DEFAULT_FILTER_SET: FilterState = {
  regionRange: RegionRange.RANGE_3,
  workPeriod: undefined,
  jobTasks: [],
  workDays: [],
  workTime: undefined,
  filterStatus: {
    any: false,
    region: false,
    workPeriod: false,
    jobTasks: false,
    workDays: false,
    workTime: false,
  },
}
